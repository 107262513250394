import React, {useState} from 'react';

import {Collapse} from 'react-collapse';
import ReactMarkdown from 'react-markdown';

import gfm from 'remark-gfm';

export default (props)=> {
    const [divOpened, setDivOpened] = useState(false);
    const toggleDivOpened =()=>{
        setDivOpened(!divOpened);
    }
    return (
        <div className='mt-2 mb-2.5'>
        <div 
            onClick={()=>toggleDivOpened()} 
            className='cursor-pointer text-xl font-bold flex flex-row  items-center justify-between'
            style={{color:'#969696'}}
        >
            {props.name}
            <div className={`ml-3 text-sm ${divOpened ? 'pointUp' : 'pointDown'}`}>
                ▼
            </div>
        </div>
        
        <Collapse isOpened={divOpened}>
            <ReactMarkdown 
                className='p-5 pt-2 pb-4 mt-4 bg-gray-50 rounded-lg md' 
                plugins={[gfm]} 
                children={props.description} 
            />    
        </Collapse>
        </div>
    )
}