import React, {useState} from 'react'
import {graphql, useStaticQuery, Link} from 'gatsby'


export default (props) => {
  
  const {allGraphCmsProduct:{nodes}} = useStaticQuery(graphql`
    query SidebarQuery {  
      allGraphCmsProduct{
        nodes {
          slug
          productTitle
        }
      }
    }
  `)

  return(
    <div className={`overflow-hidden sidebar-div hidden lg:flex`} >
      <div className='flex flex-col m-5'>
        <p className='font-bold text-3xl py-3 whitespace-nowrap'>Our Products</p>
       {nodes.map(
         product => 
         <Link 
          className='font-medium py-1'
          title={product.productTitle} 
          to={`/products/${product.slug}`}>
            ⋅ {product.productTitle}
         </Link>
       )}
      </div>
      <div className='w-full p-5 flex'>
      <Link 
        to='/contact' 
        className='bg-white text-black text-center font-medium rounded-lg px-10 py-4 text-lg '>
        Contact Us
      </Link>
      </div>
    </div>
     
  )
}