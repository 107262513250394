import React from 'react';
import {navigate, graphql} from 'gatsby'

import ProductSidebar from '../components/ProductSidebar'
import ReactMarkdown from 'react-markdown'
import gfm from 'remark-gfm'
import Img from "gatsby-image"
import borderSVG from '../assets/sidebarBorder.svg'
import Subproduct from '../components/Subproduct';
import { Fade as Hamburger } from 'hamburger-react'
import {Helmet} from 'react-helmet'

export default (props) => {
  const product = props.data.graphCmsProduct;

  return(
    <>
    <Helmet>
      <title>{product.productTitle} | Great Pine Resources</title>
      <meta name="description" content={`Learn more about ${product.productTitle}`} />
    </Helmet>
    <div className='flex flex-row flex-nowrap w-screen relative'> 
      <ProductSidebar/>
      <div className='w-full product-container'>
        <img draggable="false" src={borderSVG} className={`sidebar-border`}/>

        <div style={{ maxHeight: "800px", height: "auto" }}>
          <Img
           style={{ maxHeight: "50vh", width:'100%' }}
           imgStyle={{ objectFit: "cover" }}
            fluid={product.pictureWide.localFile.childImageSharp.fluid}
          />
        </div>
        
        <div className='info-container'>
          <h1 className='text-4xl font-medium py-5'>{product.productTitle}</h1>
          <div className='lg:pl-5'>
            <ReactMarkdown className='pb-5 md' plugins={[gfm]} children={product.description} />
            {
              product.subproduct.map(
                subproduct => (
                  <Subproduct
                     name={subproduct.subproductTitle}
                     description={subproduct.description}
                     key={subproduct.title}
                  />
                )
               )
            }
          </div>
          <hr style={{border:'1px solid #DADADA'}} className='my-5'/>
          <button onClick={()=>navigate('/contact')} 
            className='md:hidden p-4 rounded-lg bg-yellow-base hover:bg-yellow-hoverBase max-w-min whitespace-nowrap mx-auto'>
              Contact Us
          </button>
        </div>
       
      </div>

      <button className='hamburger' >
      <Hamburger 
        easing="ease-in"
        size={24}
        toggled={false}
        toggle={()=>navigate('/products')}
        rounded
        label="Show menu" />
      </button>
    </div>
    </>
    
  )
}

export const pageQuery = graphql`
  query ProductQuery($id: String){
    graphCmsProduct(id: {eq: $id}){
      productTitle
      description
      subproduct{
        id
        subproductTitle
        description
      }
      pictureWide {
        localFile{
          childImageSharp {
            fluid(maxWidth:1920,quality:90) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`